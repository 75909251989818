import React from 'react'
import { Grid, Card, CardHeader, CardContent, useMediaQuery } from '@mui/material'
import { useSignup } from './useSignup'
import { TypographyForm, ImageContainer, ImageWhats, ImageWhatsMobile } from './SignupStyles'

const Signup = ({ setDisplay }) => {
  const { handleLotameWhatsapp } = useSignup()
  const xs = useMediaQuery('(max-width: 899px)');
  const md = useMediaQuery('(min-width: 900px)');

  const renderForm = () => {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <TypographyForm align="center" sx={{ fontSize: { xs: '1.5rem', md: '1.8rem', lg: '1.8rem', xl: '2.25rem' } }}>
          Gracias por participar
        </TypographyForm>
      </Grid>
    );
  };

  return (
    <Grid container item spacing={2} sx={{ marginTop: 20 }}>
      <Grid item xs={0} md={2}></Grid>
      <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"} margin={"auto"}>
        {xs && (
          <ImageWhatsMobile
            onClick={() => {
              handleLotameWhatsapp()
              window.location.href = 'https://api.whatsapp.com/send/?phone=525588392293&text=Hola'
            }}>
            <img
              width={"100%"}
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/CuadrosDeDialogo.svg'}
              alt="DanoneFresa"
            />
          </ImageWhatsMobile>
        )}
        {md && (
          <ImageWhats
            onClick={() => {
              handleLotameWhatsapp()
              window.location.href = 'https://api.whatsapp.com/send/?phone=525588392293&text=Hola'
            }}>
            <img
              width={"100%"}
              src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/CuadrosDeDialogo.svg'}
              alt="DanoneFresa"
            />
          </ImageWhats>
        )}
        <Card variant="outlined" sx={{ borderRadius: 20, padding: 1, margin: 1, width: '100%' }}>
          <CardHeader
            title={
              <TypographyForm
                align='center'
                sx={{
                  fontSize: { xs: '1.5rem', md: '1.8rem', lg: '1.8rem', xl: '2.25rem' }
                }}
              >
                Registro de códigos de barras
              </TypographyForm>} />
          <CardContent sx={{ width: "100%", height: 350, padding: 0, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
            {renderForm()}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"}>
        <ImageContainer
          src={'https://danone50anos-cdn.mywebserver.in/assets2/svg/danone_fresa.svg'}
          alt="DanoneFresa"
          onClick={() => { setDisplay(true) }}
        />
      </Grid>
      <Grid item xs={0} md={2}></Grid>
    </Grid >
  )
}

export { Signup };
