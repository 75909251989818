export const weeks = [
  {
    week: 'Semana 1',
    dateInit: new Date(2024, 7, 10, 0, 0, 0, 0),
    dateEnd: new Date(2024, 7, 17, 23, 59, 59, 999),
    winner: 'José G.',
    dailyWinner: [
      "Susana R.",
      "Osvaldo C.",
      "Berenice M.",
      "Maria C.",
      "Erika Z.",
      "Carlos C.",
      "Sandra J.",
      "Luisa T.",
      "Celia S.",
      "Maria S.",
      "Nelly G.",
      "Evelyn V.",
      "Ana M.",
      "Pablo J.",
      "Jose P.",
      "Guadalupe R.",
      "Reyna Z.",
      "Diana V.",
      "Dulce R.",
      "Griselda C.",
      "Gabriela R.",
      "Maria P.",
      "Candelaria G.",
      "Manuela C.",
      "Jaime M.",
      "Alex C.",
      "Miriam G.",
      "Alberto J.",
      "Luis J.",
      "Carlos G.",
      "Soledad P.",
      "Paola R.",
      "Lorena M.",
      "Xochitl L.",
      "Maura R.",
      "Alfredo I.",
      "Mario D.",
      "Joel R.",
      "Isidro L.",
      "Tamara A.",
      "Sara G.",
      "Gustavo H.",
      "Beatriz E.",
      "Gerardo M.",
      "Francisco F.",
      "Mayra M.",
      "David V.",
      "Maria M.",
      "Cristobal M.",
      "Dulce H.",
      "Diana C.",
      "Marisol N.",
      "Estefania S.",
      "Jesus F.",
      "Jatziri Q.",
      "Paulina Z.",
      "Carmen G.",
      "Alba F.",
      "Alma L.",
      "Claudia M.",
      "Carlos B.",
      "Xochitl R.",
      "Elizabeth M.",
      "Xiomara M.",
      "Guiseppe C.",
      "Edgar T.",
      "Agustin M.",
      "Gloria M.",
      "Christian M.",
      "Sara D.",
      "Andrea Z.",
      "Martin C.",
      "Estela G.",
      "Jonathan J.",
      "German G.",
      "Jorge M.",
      "Victor R.",
      "Maria C.",
      "Fabiola C.",
      "Ander V.",
      "Ruben R.",
      "Selene G.",
      "Marisol D.",
      "Camelia C.",
      "Jorge J.",
      "Omar O.",
      "Maria H.",
      "Regina L.",
      "Paulo D.",
      "Diego V.",
      "Guadalupe A.",
      "Jocelyn N.",
      "Josefina C.",
      "Veronica P.",
      "Alfonso G.",
      "Veronica H.",
      "Arturo C.",
      "Christian M.",
      "Emmanuel M.",
      "Stefany R.",
      "Ana L.",
      "Adan O.",
      "Edgar R.",
      "Francisca Q.",
      "Lorena O.",
      "Viridiana S.",
      "Beatriz H.",
      "Susana B.",
      "Roberto M.",
      "Diana M.",
      "Jose I.",
      "Paola R.",
      "Cynthia L.",
      "Mario V.",
      "Rafael O.",
      "Nubia A.",
      "Miguel R.",
      "Iris C.",
      "Magdalena A.",
      "Cesar O.",
      "Jessica R.",
      "Emiliano C.",
      "Juan S.",
      "Veronica L.",
      "Briseida M.",
      "Evelyn A.",
      "Sofia M.",
      "Maria A.",
      "Perla V.",
      "Dulce G.",
      "Wendy V.",
      "Carla N.",
      "Miriam G.",
      "Jean N.",
      "Diana G.",
      "Marisol N.",
      "Adalberto R.",
      "Ricardo E.",
      "Nayeli N.",
      "Lilia R.",
      "Luis M.",
      "Mario C.",
      "Luis B.",
      "Ivan R.",
      "Raul A.",
      "Andrea C.",
      "Jessica B.",
      "Carlos G.",
      "Eugenia R.",
      "Juan M.",
      "Juan H.",
      "Ricardo C.",
      "Angel S.",
      "Jose R.",
      "Rosa M.",
      "Adriana G.",
      "Esmeralda M.",
      "Hermila I.",
      "Ana M.",
      "Laura C.",
      "Brenda S.",
      "Gloria R.",
      "Enrique P.",
      "Nancy R.",
      "Leticia G.",
      "Gloria S.",
      "Laura M.",
      "Adriana A.",
      "Mariana B.",
      "Maria H.",
      "Wilfrido B.",
      "Ariana R.",
      "Miriam A.",
      "Marion G.",
      "Socrates G.",
      "Alejandro H.",
      "Axel M.",
      "Carmen J.",
      "Alejandra G.",
      "Beatriz B.",
      "Norma C.",
      "Jose P.",
      "Juan Q.",
      "Karen A.",
      "Ivan M.",
      "Jose R.",
      "Maria R.",
      "Maria R.",
      "Maria M.",
      "Arturo P.",
      "Julio H.",
      "Carlos C.",
      "Jorge H.",
      "Karla B.",
      "Maria R.",
      "Israel M.",
      "Alejandro O.",
      "Santiago B.",
      "Mario V.",
      "Claudia F.",
      "Sandra G.",
      "Gloria R.",
      "Alberto F.",
      "Monica M.",
      "Sandra S.",
      "Christian A.",
      "Blanca N.",
      "Gabriela A.",
      "Enrique M.",
      "Rigoberto S.",
      "Edher L.",
      "Marypaz G.",
      "Fany G.",
      "Alonso N.",
      "Cesar A.",
      "Iliana M.",
      "Nancy R.",
      "Diana E.",
      "Luis P.",
      "Laura G.",
      "Ana M.",
      "Maria C.",
      "Myriam M.",
      "Juan R.",
      "Jose M.",
      "Antonio R.",
      "Jesus G.",
      "Pamela R.",
      "Amalia G.",
      "Joceline A.",
      "Gustavo S.",
      "Olivia S.",
      "Jose R.",
      "Maria A.",
      "Laura G.",
      "Karina B.",
      "Celia J.",
      "Paola E.",
      "Jose C.",
      "Anette O.",
      "Martin C.",
      "Juana V.",
      "Fernanda S.",
      "Cintia Z.",
      "Isabel F.",
      "Sonia R.",
      "Karen G.",
      "Veronica A.",
      "Andrea R.",
      "Diana A.",
      "Jorge O.",
      "Jose S.",
      "Jose L.",
      "Ana M.",
      "Ana V.",
      "Jose A.",
      "Daniel P.",
      "Jesus T.",
      "Maria C.",
      "Arturo C.",
      "Fatima V.",
      "Rosa M.",
      "Vianey R.",
      "Diego H.",
      "Angel L.",
      "Gabriela M.",
      "Nahum P.",
      "Alejandro H.",
      "Gloria C.",
      "Jose M.",
      "Olivia M.",
      "Gustavo V.",
      "Sandra J.",
      "Mariel B.",
      "Carlos H.",
      "Miguel M.",
      "Selvia B.",
      "Arnulfo M.",
      "Maria R.",
      "Valery C.",
      "Jose L.",
      "Leonardo L.",
      "Rafael V.",
      "Luis M.",
      "Robira D.",
      "Alma D.",
      "Laura R.",
      "Patricia M.",
      "Juan P.",
      "Victor J.",
      "Adriana G.",
      "Diana T.",
      "Jose B.",
      "Esperanza M.",
      "Esther B.",
      "Jessica M.",
      "Jessica B.",
      "Jesus G.",
      "Maria T.",
      "Maricela L.",
      "Francisco G.",
      "Alma M."
    ]      
  },
  {
    week: 'Semana 2',
    dateInit: new Date(2024, 7, 18, 0, 0, 0, 0),
    dateEnd: new Date(2024, 7, 25, 23, 59, 59, 999),
    winner: 'Cinthya H.',
    dailyWinner: [
      "Patricia H.",
      "Martha R.",
      "Nataly G.",
      "Maria M.",
      "Miranda A.",
      "Jhovani M.",
      "Barbara T.",
      "Maria G.",
      "Daniel C.",
      "Ernesto M.",
      "Oscar E.",
      "Rosa C.",
      "Carlos A.",
      "Mario M.",
      "Guadalupe A.",
      "Perla M.",
      "Alan M.",
      "Solange R.",
      "Juan M.",
      "Hugo C.",
      "Elizabeth P.",
      "Emilio G.",
      "Miguel G.",
      "Doris C.",
      "Diego M.",
      "Edna C.",
      "Mariana P.",
      "Miguel G.",
      "Eduardo M.",
      "Hector C.",
      "Martin C.",
      "Joselyn R.",
      "Taurino G.",
      "Veronica S.",
      "Victor I.",
      "Abril B.",
      "Jubencio R.",
      "Marco L.",
      "Gicela R.",
      "Jose C.",
      "Lourdes D.",
      "Paola H.",
      "Eduardo G.",
      "Karla M.",
      "Moises G.",
      "Luis M.",
      "Oved L.",
      "Victor B.",
      "Carla S.",
      "Maria G.",
      "Jacaranda P.",
      "Lidia H.",
      "Claudia E.",
      "Matilde G.",
      "Rafael G.",
      "Daniela C.",
      "Viviana S.",
      "Yuliana B.",
      "Sofia B.",
      "Maria H.",
      "Monica R.",
      "Hector M.",
      "Marisa C.",
      "Jose G.",
      "Guillermo M.",
      "Sara H.",
      "Jordi R.",
      "Rosa C.",
      "Beatriz N.",
      "Carlos M.",
      "Christian G.",
      "Demetrio B.",
      "Maria O.",
      "Dara V.",
      "Alicia E.",
      "Jorge J.",
      "Zita C.",
      "Diego P.",
      "Valeria N.",
      "Virgilio D.",
      "Alexis C.",
      "Aaron S.",
      "Javier C.",
      "Hayde P.",
      "Alejandra M.",
      "Leticia C.",
      "Evangelina C.",
      "Hugo S.",
      "Nestor M.",
      "Hector G.",
      "Selene A.",
      "Maria L.",
      "Rowena R.",
      "Sandra M.",
      "Diana T.",
      "Bibiana M.",
      "Rosa S.",
      "Christian A.",
      "Fernando R.",
      "Sandra S.",
      "Nadia L.",
      "Gustavo C.",
      "Maria P.",
      "Angel F.",
      "Juan L.",
      "Emilio L.",
      "Erika Z.",
      "Fernando G.",
      "Francisco V.",
      "Andrea S.",
      "Carlos M.",
      "Lili F.",
      "Esperanza M.",
      "Maria G.",
      "Maria S.",
      "Andrea V.",
      "Erik C.",
      "Israel R.",
      "Adela S.",
      "Fady E.",
      "Jose H.",
      "Viridiana G.",
      "Jose E.",
      "Fernando V.",
      "Claudia C.",
      "Paola S.",
      "Hugo C.",
      "Lina B.",
      "Jacqueline B.",
      "Jorge B.",
      "Elsi R.",
      "Maria M.",
      "Yazmin F.",
      "Diana R.",
      "Cesar A.",
      "Jade S.",
      "Manuel R.",
      "Brisa E.",
      "Jose R.",
      "Mariana M.",
      "Sherlyn A.",
      "Yadira T.",
      "Jair J.",
      "Jhovani M.",
      "Jose G.",
      "Juan I.",
      "Larisa G.",
      "Xochitl Q.",
      "Elia G.",
      "Paola R.",
      "Lorena R.",
      "Nancy L.",
      "Joel R.",
      "Jaqueline O.",
      "Lucia O.",
      "Edna O.",
      "Julio S.",
      "Jonathan M.",
      "Manuel P.",
      "Lino R.",
      "Jorge H.",
      "Oscar G.",
      "Denisse R.",
      "Susana C.",
      "Manuel C.",
      "Ashley R.",
      "Pablo R.",
      "Luisa L.",
      "Cynthia F.",
      "Rafael C.",
      "Selene C.",
      "Irene H.",
      "Enrique C.",
      "Jorge H.",
      "Diana V.",
      "Salome B.",
      "Karen A.",
      "Kenia C.",
      "Luis R.",
      "Ana C.",
      "Juan L.",
      "Librado E.",
      "Luis G.",
      "Marcos U.",
      "Edgar Z.",
      "Nayeli O.",
      "Jorge M.",
      "Leonardo S.",
      "Maria M.",
      "Karen A.",
      "Luz M.",
      "Eduardo S.",
      "Carlos M.",
      "David O.",
      "Ana M.",
      "Alejandra L.",
      "Luz R.",
      "Leslie C.",
      "Juan M.",
      "Cuitlahuac R.",
      "Elvia R.",
      "Jose C.",
      "Oscar G.",
      "Liliana E.",
      "Lizeth H.",
      "Carlos L.",
      "Monica G.",
      "Luis M.",
      "Monica F.",
      "Regina U.",
      "Karen R.",
      "Dalila A.",
      "Esmeralda Q.",
      "Maria T.",
      "Concepcion G.",
      "Susana R.",
      "Ana G.",
      "Maria H.",
      "Marisela T.",
      "Nayeli N.",
      "Noe G.",
      "Pahoran P.",
      "Pedro L.",
      "Sarai C.",
      "Nadia E.",
      "Blanca Z.",
      "Omar L.",
      "Israel S.",
      "Ulises M.",
      "Jose T.",
      "Gabriela C.",
      "Alicia A.",
      "Sarahi M.",
      "Ana R.",
      "Francisca R.",
      "Alexa S.",
      "Clara C.",
      "Andy G.",
      "Sergio M.",
      "Wilma M.",
      "Alejandra O.",
      "Rene L.",
      "Sergio J.",
      "Juan D.",
      "Maria F.",
      "Julio R.",
      "Maria R.",
      "Alfredo M.",
      "Octavio S.",
      "Ana C.",
      "Maria V.",
      "Victoria V.",
      "Bertha M.",
      "Jose A.",
      "Paulino P.",
      "Miguel D.",
      "Ernesto L.",
      "Antonia G.",
      "Patricia V.",
      "Octavio B.",
      "Miguel C.",
      "Andres A.",
      "Isaias A.",
      "Sandra O.",
      "Esther A.",
      "Miriam G.",
      "Martha O.",
      "Marlon V.",
      "Daniela D.",
      "Cristhian S.",
      "Citlali C.",
      "Natividad M.",
      "Ana M.",
      "Juan C.",
      "Belisario R.",
      "Francisco D.",
      "Luis B.",
      "Lucia M.",
      "Laura F.",
      "Enrique C.",
      "Carolina M.",
      "Diana R.",
      "Diego C.",
      "Jassiel M.",
      "Allison E.",
      "Juana L.",
      "Karla M.",
      "Isaac D.",
      "Maria M.",
      "Arizbeth R.",
      "Roxana G.",
      "Rosa V.",
      "Nuria T.",
      "Patricia D.",
      "Rosa E.",
      "Laura C.",
      "Eduardo I.",
      "Karina J.",
      "Rogelio L.",
      "Angel J.",
      "Paris P.",
      "Emiliano L."
    ]
  },
  {
    week: 'Semana 3',
    dateInit: new Date(2024, 7, 26, 0, 0, 0, 0),
    dateEnd: new Date(2024, 8, 2, 23, 59, 59, 999),
    winner: 'Gabriel T.',
    dailyWinner: [
      "Eugenia G.",
      "Jacobo S.",
      "Ariana P.",
      "Karen M.",
      "Edith C.",
      "Cindy U.",
      "Elvia C.",
      "Flor R.",
      "Juan B.",
      "Saul G.",
      "Angel C.",
      "Neftali C.",
      "Luis R.",
      "Karla C.",
      "Fernanda Q.",
      "Sandra C.",
      "David G.",
      "Roberto A.",
      "Sonia B.",
      "Rigoberto B.",
      "Ofelia M.",
      "Francisco M.",
      "Manuel P.",
      "Joaquin O.",
      "Dulce H.",
      "Ociel M.",
      "Raul G.",
      "Miguel O.",
      "Valentina R.",
      "Ruth O.",
      "Veronica R.",
      "Paola I.",
      "Aldo N.",
      "Cristina B.",
      "Maria L.",
      "Leticia G.",
      "Ana S.",
      "Norma Z.",
      "Oscar R.",
      "Alex C.",
      "Carolina H.",
      "Maribel S.",
      "Pedro A.",
      "Daniela N.",
      "Jose R.",
      "Ricardo G.",
      "Emmanuel P.",
      "Celia O.",
      "Juan Z.",
      "Carina I.",
      "Raul H.",
      "Brenda E.",
      "Jesus M.",
      "Samantha M.",
      "Raul R.",
      "Alejandro C.",
      "Nelly C.",
      "Rodolfo V.",
      "Rogelio P.",
      "Miguel F.",
      "Maria R.",
      "Alfonso V.",
      "Rafael C.",
      "Juana O.",
      "Cuauhtemoc R.",
      "Gloria V.",
      "Maria M.",
      "Angelica R.",
      "Alejandra B.",
      "Idalia C.",
      "Angel A.",
      "Ulises C.",
      "Aldo L.",
      "Susana L.",
      "Margarita B.",
      "Brenda C.",
      "Maria M.",
      "Maria V.",
      "Rosalba G.",
      "Elizabeth G.",
      "Shaday G.",
      "Juan A.",
      "Luis O.",
      "Gerardo A.",
      "Sandra V.",
      "Angelica C.",
      "Eli H.",
      "Raul C.",
      "Carlos L.",
      "Manuel J.",
      "Angel F.",
      "Karen R.",
      "Beatriz L.",
      "Victoria A.",
      "Jorge P.",
      "Mayra G.",
      "Maria T.",
      "Nailea V.",
      "Luis G.",
      "Sara R.",
      "Miguel V.",
      "Maria E.",
      "Maria L.",
      "Martha S.",
      "Maria M.",
      "Celene M.",
      "Victor W.",
      "Alejandro A.",
      "Carolina F.",
      "Marisol G.",
      "Luis V.",
      "Ricardo R.",
      "Marina A.",
      "Olivia H.",
      "Caleb F.",
      "Esther R.",
      "Ramon R.",
      "Teresa J.",
      "Maria M.",
      "Veronica E.",
      "Ma H.",
      "Raul H.",
      "Eduardo R.",
      "Ema S.",
      "Edilberto J.",
      "Monica D.",
      "Alejandro G.",
      "Esteban R.",
      "Maria C.",
      "Esmeralda H.",
      "Maria L.",
      "Jaqueline F.",
      "Mario E.",
      "Elizabeth M.",
      "Emanuel I.",
      "Oscar M.",
      "Ileana H.",
      "Ana D.",
      "Maria J.",
      "Georgina T.",
      "Octavio R.",
      "Pamela A.",
      "Julia C.",
      "Erik A.",
      "Oscar S.",
      "Juan T.",
      "Jessica M.",
      "Adriana O.",
      "Diana I.",
      "Nallely E.",
      "Cynthia L.",
      "Ana M.",
      "Ana V.",
      "Jorge M.",
      "Laura R.",
      "Fran P.",
      "Jorge A.",
      "Nancy V.",
      "Felipe R.",
      "Luz F.",
      "Demetrio S.",
      "Griselda V.",
      "Jessica J.",
      "Adriana D.",
      "Salvador L.",
      "Carlos B.",
      "Virginia E.",
      "Benjamin H.",
      "Arturo C.",
      "Paulina R.",
      "Said C.",
      "Rogelio C.",
      "Maria S.",
      "Miriam B.",
      "Javier J.",
      "Liliana R.",
      "Clara M.",
      "Jose V.",
      "Francisco L.",
      "Jesus M.",
      "Christian S.",
      "Maria L.",
      "Perla D.",
      "Ulises A.",
      "Jose C.",
      "Carla E.",
      "Sandra G.",
      "Orlando M.",
      "Tomas A.",
      "Basilio R.",
      "Araceli R.",
      "Erika B.",
      "Monica S.",
      "Areli M.",
      "Gabriela T.",
      "Brenda C.",
      "Miriam H.",
      "Laura P.",
      "Gustavo M.",
      "Nancy R.",
      "Alan P.",
      "Jose T.",
      "Juan M.",
      "Abril C.",
      "Veronica A.",
      "Mirna M.",
      "Alfonso A.",
      "Santa C.",
      "Noemi C.",
      "Isabel H.",
      "Xochilt R.",
      "Jose M.",
      "Jesus R.",
      "Francisco M.",
      "Minerva M.",
      "Harold M.",
      "Rafael C.",
      "Johan M.",
      "Martha C.",
      "Rosalba L.",
      "Laura J.",
      "Pablo S.",
      "Juan M.",
      "Belen H.",
      "Susana L.",
      "Efrain M.",
      "Edgardo L.",
      "Melisa C.",
      "Maria G.",
      "Adriana M.",
      "Cecilia B.",
      "Vicente B.",
      "Maria B.",
      "Carmen S.",
      "Maria M.",
      "Rosario B.",
      "Arcelia R.",
      "Eugenia V.",
      "Maria T.",
      "Beatriz I.",
      "Paulette S.",
      "Hugo S.",
      "Jorge S.",
      "Liliana H.",
      "Edna N.",
      "Arturo G.",
      "Maria C.",
      "Mayra L.",
      "Laura N.",
      "Alex M.",
      "Carlos A.",
      "Silvano L.",
      "Alexia T.",
      "Chantal A.",
      "Berenice C.",
      "Oscar P.",
      "Jose A.",
      "Katherine H.",
      "Diana V.",
      "Jocelyn L.",
      "Reyna C.",
      "Laura G.",
      "Luis O.",
      "Raymundo B.",
      "Fabian G.",
      "Luz C.",
      "Osvaldo J.",
      "Arturo C.",
      "Olga O.",
      "Edmundo R.",
      "Juana C.",
      "Oscar P.",
      "Diana L.",
      "Jose L.",
      "Karen S.",
      "Marco B.",
      "Manuel B.",
      "Rebeca N.",
      "Jose G.",
      "Cristina P.",
      "Gerardo H.",
      "Jorge F.",
      "Alberto N.",
      "Maria V.",
      "Rosa J.",
      "Bertha D.",
      "Maria M.",
      "Leonardo L.",
      "Nayelli M.",
      "Nora V.",
      "Manuel K.",
      "Sergio O.",
      "Monica C.",
      "Ivonne S.",
      "Oswaldo L.",
      "Carlos B.",
      "Karla C.",
      "Pedro P.",
      "Juan L.",
      "Brenda G.",
      "Karina E.",
      "Erika G."
    ]
  },
  {
    week: 'Semana 4',
    dateInit: new Date(2024, 8, 3, 0, 0, 0, 0),
    dateEnd: new Date(2024, 8, 10, 23, 59, 59, 999),
    winner: 'Gustavo H.',
    dailyWinner: [
      "Diana G.",
      "Roberto C.",
      "Juan J.",
      "Leticia V.",
      "Erika D.",
      "Veronica M.",
      "Jesus H.",
      "Blanca G.",
      "Martina L.",
      "Sergio M.",
      "Rosa B.",
      "Gabriela R.",
      "Teresita B.",
      "Marisol O.",
      "Carolina R.",
      "Andrea C.",
      "Ismael C.",
      "Janett B.",
      "Carlos M.",
      "Juan A.",
      "Juan G.",
      "Cristina G.",
      "Jose A.",
      "Magdalena H.",
      "Abel L.",
      "Ricardo L.",
      "Andres O.",
      "Emma J.",
      "Montserrat S.",
      "Jaime C.",
      "Guadalupe F.",
      "Lizeth V.",
      "Ruth S.",
      "Francia S.",
      "Fernando D.",
      "Gonzalo A.",
      "Daniel C.",
      "Mayra A.",
      "Jose S.",
      "Sandra S.",
      "Montserrat O.",
      "Aldo R.",
      "Adriana S.",
      "Alma J.",
      "Valeria H.",
      "Eduardo C.",
      "Jose A.",
      "Diana G.",
      "Christian E.",
      "Maria V.",
      "Julio M.",
      "Maria M.",
      "Maria A.",
      "Rafael S.",
      "Samantha C.",
      "Rosa L.",
      "Raquel A.",
      "Angel L.",
      "Carla D.",
      "Laura S.",
      "Cesar L.",
      "Josefa C.",
      "Mariela V.",
      "Diana C.",
      "Jose C.",
      "Xochitl R.",
      "Silvia V.",
      "Arturo O.",
      "Karen Z.",
      "Maria V.",
      "Juan A.",
      "Blanca F.",
      "Celeste M.",
      "Alessandra A.",
      "Horacio G.",
      "Angel V.",
      "Teresa H.",
      "Eva V.",
      "Jose V.",
      "Rogelio V.",
      "Oscar O.",
      "Monserrat S.",
      "Sergio M.",
      "Alejandra S.",
      "Horacio A.",
      "Jose R.",
      "Javier S.",
      "Lizet G.",
      "Mercedes J.",
      "Gabriela C.",
      "Micaela R.",
      "Miguel F.",
      "Jose G.",
      "Luis L.",
      "Perla R.",
      "Jesus C.",
      "Pedro S.",
      "Alfredo G.",
      "Christian B.",
      "Carlos C.",
      "Robin R.",
      "Alejandra A.",
      "Miguel S.",
      "Christian N.",
      "Erendira S.",
      "Jorge O.",
      "Diana M.",
      "Estefania T.",
      "Sandra G.",
      "Martha H.",
      "Diana A.",
      "Ana A.",
      "Veronica C.",
      "Ingrid L.",
      "Nancy R.",
      "Alan V.",
      "Israel R.",
      "Maria G.",
      "Angelica C.",
      "Ambrocio C.",
      "Juana P.",
      "Jorge A.",
      "Luis R.",
      "Dany H.",
      "Maria G.",
      "Rafael C.",
      "Cesar S.",
      "Jocelyn T.",
      "Luis Z.",
      "Zem B.",
      "Nayeli V.",
      "Juana A.",
      "Angelica V.",
      "Alejandro R.",
      "Claudio R.",
      "Sandra O.",
      "Fernando A.",
      "Oscar D.",
      "Aaron G.",
      "Felipe T.",
      "Raquel F.",
      "Flor F.",
      "Miguel H.",
      "Erick T.",
      "Diana P.",
      "Ana A.",
      "Clara N.",
      "Edgar S.",
      "Sandra G.",
      "Iridian J.",
      "Ramon L.",
      "Sara V.",
      "Wendy A.",
      "Jonathan P.",
      "Apolinar L.",
      "Marisol R.",
      "Janette L.",
      "Maria P.",
      "Jaime H.",
      "Silvia G.",
      "Eliseo R.",
      "Cynthia G.",
      "Claudia V.",
      "Adriana R.",
      "Yasmin G.",
      "Isaac Z.",
      "Alejandro P.",
      "Alejandro M.",
      "Reynalda R.",
      "Sergio H.",
      "Laura T.",
      "Jose G.",
      "Lucila M.",
      "Maria H.",
      "Alejandro P.",
      "Abigail V.",
      "Julio C.",
      "Argelia B.",
      "Magdalena G.",
      "Guadalupe M.",
      "Miguel R.",
      "Maria S.",
      "Nancy T.",
      "Erik A.",
      "Manuela A.",
      "Jesus S.",
      "David A.",
      "Maria E.",
      "Perla V.",
      "Maria P.",
      "Joana L.",
      "Mauricio S.",
      "Fabiola S.",
      "Sandra F.",
      "Berenice B.",
      "Alicia V.",
      "Maira P.",
      "Juan I.",
      "Mildred N.",
      "Isidro A.",
      "Ivonne C.",
      "Patricia B.",
      "Claudia N.",
      "Abigail L.",
      "Ana S.",
      "Karla D.",
      "Ana R.",
      "Andrea C.",
      "Edgar C.",
      "Juan N.",
      "Yoselin P.",
      "Claudia S.",
      "Bernardo M.",
      "Veronica R.",
      "Omar G.",
      "Luz T.",
      "Ivonne G.",
      "Christian G.",
      "Miguel A.",
      "Mario L.",
      "Angelica L.",
      "Rafael R.",
      "Consuelo R.",
      "Daniela H.",
      "Sebastian S.",
      "Antonio R.",
      "Samantha L.",
      "Hugo O.",
      "Oscar N.",
      "Beatriz M.",
      "Edgar R.",
      "Alejandro L.",
      "Samantha E.",
      "Jessica M.",
      "Beatriz T.",
      "Lilia B.",
      "Francisco E.",
      "Cinthya G.",
      "Ulises A.",
      "Pamela S.",
      "David M.",
      "Elva R.",
      "Kevin M.",
      "Alberto R.",
      "Katia N.",
      "Ariana T.",
      "Joselyn R.",
      "Abril Q.",
      "Monserrat G.",
      "Karina V.",
      "Monica Z.",
      "Maria I.",
      "Karla G.",
      "Patricia R.",
      "Denisse L.",
      "Gabriel M.",
      "Antonia H.",
      "Carolina T.",
      "Katya N.",
      "Laura R.",
      "Ivet O.",
      "Modesto N.",
      "Maria F.",
      "Juan G.",
      "Maria L.",
      "Julio G.",
      "Edgar R.",
      "Monica A.",
      "Joana R.",
      "Adriana F.",
      "Mario G.",
      "Jose B.",
      "Arisbet C.",
      "Karen T.",
      "Stephany P.",
      "Valentina O.",
      "Julieta F.",
      "Gabriel V.",
      "Adriana H.",
      "Reyna D.",
      "Alma C.",
      "Ana G.",
      "Lorena F.",
      "Virginia V.",
      "Virgina R.",
      "Brenda R.",
      "Yonatan R.",
      "Gerardo A.",
      "Rolando A.",
      "Angelina B.",
      "Isidro U.",
      "Natalia P.",
      "Jose N.",
      "Ruben R.",
      "Javier O.",
      "Mauricio D.",
      "Leticia R.",
      "Karina P.",
      "Francisco A.",
      "Joel G.",
      "Mariana M.",
      "Sarahi R."
    ]
  },
  {
    week: 'Semana 5',
    dateInit: new Date(2024, 8, 11, 0, 0, 0, 0),
    dateEnd: new Date(2024, 8, 18, 23, 59, 59, 999),
    winner: 'Jesús R.',
    dailyWinner: [
      "Jose C.",  
      "Carlos G.",  
      "Maria C.",  
      "Marisela T.",  
      "Dora E.",  
      "Juan H.",  
      "Carlos M.",  
      "Ricardo E.",  
      "Daniela M.",  
      "Macarena V.",  
      "Elisa C.",  
      "Maria A.",  
      "Laura G.",  
      "Sandra L.",  
      "Ana R.",  
      "Jesus M.",  
      "Victor M.",  
      "Maria F.",  
      "Maria A.",  
      "Linda Z.",  
      "Erika R.",  
      "Marlene A.",  
      "Ricardo P.",  
      "Lidia R.",  
      "Grecia R.",  
      "Karla V.",  
      "Maria H.",  
      "Cristina R.",  
      "Gonzalo P.",  
      "Andrea P.",  
      "Araceli M.",  
      "Rogelio C.",  
      "Vicente H.",  
      "Victor C.",  
      "Sergio A.",  
      "Ernesto H.",  
      "EvAngelina O.",  
      "Karen I.",  
      "Daniel L.",  
      "Erick F.",  
      "Andres M.",  
      "Miriam M.",  
      "Maria M.",  
      "Mario R.",  
      "Brenda J.",  
      "Gabriela P.",  
      "Luis R.",  
      "Gerardo S.",  
      "Lucero G.",  
      "Joshue P.",  
      "Juan O.",  
      "Mayra A.",  
      "Norma N.",  
      "Briceida M.",  
      "Jonathan R.",  
      "Roberto V.",  
      "Alejandra G.",  
      "Areli C.",  
      "Cecilia C.",  
      "Fred B.",  
      "Blanca G.",  
      "Juana V.",  
      "Laura L.",  
      "Gabriela G.",  
      "Celia F.",  
      "Agustin S.",  
      "Andrea A.",  
      "Orlando P.",  
      "Adaly C.",  
      "Juan M.",  
      "Alejandro M.",  
      "Francisco M.",  
      "Adriana P.",  
      "Jose H.",  
      "Cleotilde U.",  
      "Ricardo R.",  
      "Abril C.",  
      "Paola C.",  
      "Maria A.",  
      "Jorge S.",  
      "Blanca P.",  
      "Guadalupe P.",  
      "Atenas M.",  
      "Diana Q.",  
      "Alejandro C.",  
      "Adriana S.",  
      "Brenda L.",  
      "Jose M.",  
      "Rafael O.",  
      "Jose Z.",  
      "Jorge M.",  
      "Dulce G.",  
      "Maria H.",  
      "Noe J.",  
      "Criss V.",  
      "Victor M.",  
      "Kenneth G.",  
      "Brenda P.",  
      "Iby T.",  
      "Sarahi S.",  
      "Monserrat B.",  
      "Henry T.",  
      "Abril E.",  
      "Alicia G.",  
      "Manuel R.",  
      "Karen S.",  
      "Alicia P.",  
      "Clio R.",  
      "Susana T.",  
      "Armando A.",  
      "Luis L.",  
      "Jorge S.",  
      "Hector R.",  
      "Veronica O.",  
      "Andres Q.",  
      "Andres L.",  
      "Josue M.",  
      "Usiesther C.",  
      "Paulina L.",  
      "Jose M.",  
      "Mariana M.",  
      "Diana A.",  
      "Gabriela V.",  
      "Gloria L.",  
      "Cesar H.",  
      "Christian C.",  
      "Paulina S.",  
      "Mariela S.",  
      "Gabriel C.",  
      "Karina M.",  
      "Maria M.",  
      "Alberto C.",  
      "Luis O.",  
      "Jose S.",  
      "Margarita R.",  
      "Raul H.",  
      "Juan C.",  
      "Guadalupe Z.",  
      "Carlos Y.",  
      "Adzuira B.",  
      "Nestor H.",  
      "Carolina G.",  
      "Juan E.",  
      "Jonathan F.",  
      "Giovanna H.",  
      "Magaly P.",  
      "Carla B.",  
      "Carmen G.",  
      "Maria A.",  
      "Reyna R.",  
      "Yenny P.",  
      "Juan G.",  
      "Arturo B.",  
      "Rafael H.",  
      "Luis E.",  
      "Valentin M.",  
      "Carlos M.",  
      "Maria G.",  
      "Mayra V.",  
      "Nancy O.",  
      "Jose G.",  
      "Maribel C.",  
      "Yanssen O.",  
      "Josefa C.",  
      "Aracely V.",  
      "Ricardo V.",  
      "Juana T.",  
      "David G.",  
      "Liliana E.",  
      "Patricia G.",  
      "Fidel S.",  
      "Andrea G.",  
      "Diana P.",  
      "Nigel C.",  
      "Maria Z.",  
      "Teresa A.",  
      "Florencio T.",  
      "Gaspar M.",  
      "David H.",  
      "Pablo R.",  
      "David B.",  
      "Hugo P.",  
      "Gerardo G.",  
      "David O.",  
      "Karla L.",  
      "Carlos N.",  
      "Alma V.",  
      "Salvador O.",  
      "Blanca M.",  
      "Paola V.",  
      "Ana L.",  
      "Sara V.",  
      "Jaqueline C.",  
      "Eugenio A.",  
      "Victor L.",  
      "Zaira C.",  
      "Karla G.",  
      "Jose Z.",  
      "Jose G.",  
      "Nayeli P.",  
      "Nancy H.",  
      "Jose S.",  
      "Julieta M.",  
      "Jose G.",  
      "Juan V.",  
      "Paola L.",  
      "Carolina R.",  
      "Zenaido R.",  
      "Maria C.",  
      "Yolanda S.",  
      "Jorge S.",  
      "Laura R.",  
      "Alexander M.",  
      "Ruben C.",  
      "Maria R.",  
      "Antonia I.",  
      "Dulce A.",  
      "Andrea A.",  
      "Jose G.",  
      "Diana Q.",  
      "Maribel C.",  
      "Ana G.",  
      "Eder G.",  
      "Pablo P.",  
      "David U.",  
      "Rosa L.",  
      "Lizeth G.",  
      "Guillermo S.",  
      "Denisse S.",  
      "Karla G.",  
      "Sofia S.",  
      "Marubia C.",  
      "Ivette O.",  
      "Efrain S.",  
      "Lorena V.",  
      "Yesica H.",  
      "Elsa I.",  
      "Gisel R.",  
      "Nidia S.",  
      "Maria R.",  
      "Maria P.",  
      "Mario M.",  
      "Jose C.",  
      "Edgar P.",  
      "Julia R.",  
      "Ana D.",  
      "Ludivina G.",  
      "Osvaldo V.",  
      "Jonathan R.",  
      "Karla G.",  
      "Nelly H.",  
      "Luvia R.",  
      "David G.",  
      "Natividad G.",  
      "Prospero G.",  
      "Narcizo P.",  
      "Maria F.",  
      "Lydia G.",  
      "Armando P.",  
      "Jose L.",  
      "Eduardo M.",  
      "Iran J.",  
      "Ariel S.",  
      "Emily T.",  
      "Julio M.",  
      "Nahum J.",  
      "Guadalupe S.",  
      "Juan R.",  
      "Cesar H.",  
      "Abigail H.",  
      "Angelina C.",  
      "Juan O.",  
      "Aristofanes C.",  
      "Viviana A.",  
      "Fernando G.",  
      "Karla J.",  
      "Jessica B.",  
      "Veronica E.",  
      "Jordy V.",  
      "Francisco V.",  
      "Noe B.",  
      "Miriam T.",  
      "Samantha S.",  
      "Miguel N.",  
      "Judith B.",  
      "Luz F.",  
      "Carlos M.",  
      "Jose P.",  
      "Luis Z.",  
      "Sandra R.",  
      "Marcos H.",  
      "Josefina R.",  
      "Arturo D.",  
      "Diana R.",  
      "Miriam R.",  
      "Elizabeth M.",  
      "Enrique V.",  
      "Sofia O.",  
      "Silvia S.",  
      "Hilcia H.",  
      "Miriam S.",  
      "Maria E."
    ]
  },
  {
    week: 'Semana 6',
    dateInit: new Date(2024, 8, 19, 0, 0, 0, 0),
    dateEnd: new Date(2024, 8, 26, 23, 59, 59, 999),
    winner: 'Susana M.',
    dailyWinner: [
      "Allan N.",
      "Leticia G.",
      "Elias G.",
      "Marlen B.",
      "Jacobo S.",
      "Vanessa Z.",
      "Alexis P.",
      "Ana C.",
      "Maria L.",
      "Jorge A.",
      "Ramon N.",
      "Hugo V.",
      "Cristina R.",
      "Jose S.",
      "Jaime R.",
      "Karina B.",
      "Maria G.",
      "Alejandra R.",
      "Silvia H.",
      "Sandra L.",
      "Israel G.",
      "Lucia C.",
      "Christian G.",
      "Ana M.",
      "Eduardo N.",
      "Silvia G.",
      "Jorge R.",
      "Jonathan T.",
      "Sergio M.",
      "Ximena H.",
      "Susana R.",
      "Adrian O.",
      "Amadeo R.",
      "Claudia A.",
      "Monico S.",
      "Oscar T.",
      "Rodolfo S.",
      "Briceyda Z.",
      "Saury P.",
      "Arturo C.",
      "Gema G.",
      "Rubi H.",
      "Javier C.",
      "Isaac C.",
      "Israel C.",
      "Cindy R.",
      "Viridiana N.",
      "Dolores Z.",
      "Juan R.",
      "Carlos V.",
      "Alfredo B.",
      "Gabriela R.",
      "Jessica A.",
      "Omar N.",
      "Ricardo H.",
      "Teresa Z.",
      "Lucia O.",
      "Edgar M.",
      "Neria O.",
      "Elena S.",
      "Teresa O.",
      "Carla C.",
      "Patricia A.",
      "Luis B.",
      "Ilse L.",
      "Mario O.",
      "Cynthia G.",
      "Jose D.",
      "Adriana H.",
      "Cupertina V.",
      "Ana C.",
      "Pedro G.",
      "Hector P.",
      "Jhansel C.",
      "Dulce G.",
      "Lucia S.",
      "Marcela P.",
      "Nayeli G.",
      "Alan P.",
      "Adolfo T.",
      "Marco N.",
      "Claudia L.",
      "Luisa M.",
      "Adriana B.",
      "Lizbeth Z.",
      "Jesus C.",
      "Thalia B.",
      "Alfredo S.",
      "Jose C.",
      "Sandra B.",
      "Hector C.",
      "Belen L.",
      "Claudia S.",
      "Fabiola R.",
      "Nayely F.",
      "Maricarmen A.",
      "Alicia C.",
      "Aldo R.",
      "Jose C.",
      "Claudia O.",
      "Norma S.",
      "Zinnia P.",
      "Mario H.",
      "Jose L.",
      "Jaziel L.",
      "Alejandra S.",
      "Irma B.",
      "Sergio C.",
      "Luis G.",
      "Ady A.",
      "Iliana K.",
      "Octavio G.",
      "Angelica V.",
      "Sandra M.",
      "Janik M.",
      "Virginia A.",
      "Ignacio T.",
      "Erick C.",
      "Maria O.",
      "Paulina N.",
      "Gabriel G.",
      "Luis P.",
      "Marco V.",
      "Gloria M.",
      "Karina E.",
      "Soledad C.",
      "Rosa A.",
      "Antonio C.",
      "Jose C.",
      "Rafael O.",
      "Adrian B.",
      "Javier G.",
      "Alexa M.",
      "Mary H.",
      "Jorge H.",
      "Romeo C.",
      "Maria L.",
      "Jesus A.",
      "Alexander C.",
      "Francisco V.",
      "Daniela S.",
      "Ricardo S.",
      "Lorena M.",
      "Enrique T.",
      "Laura R.",
      "Oriana M.",
      "Gerardo S.",
      "Maria H.",
      "Lidia P.",
      "Evelyn G.",
      "Sergio S.",
      "Emmanuel M.",
      "Frederik S.",
      "Yoani S.",
      "Samuel L.",
      "Erik M.",
      "Jose L.",
      "Ana B.",
      "Juana R.",
      "Rosareli H.",
      "Susana M.",
      "Joaquin O.",
      "Fanny M.",
      "Yazmin T.",
      "Lenin B.",
      "Vanessa M.",
      "Stephany R.",
      "Nora N.",
      "Maria M.",
      "Jose Z.",
      "Laura C.",
      "Paulo S.",
      "Sofia L.",
      "Jesica E.",
      "Alberto M.",
      "Salvador R.",
      "Mario P.",
      "Rodrigo J.",
      "Francisco H.",
      "Jesus R.",
      "Cruz C.",
      "Araceli G.",
      "Leticia C.",
      "Fabiola M.",
      "Carlos A.",
      "Leticia S.",
      "Jose M.",
      "Maria P.",
      "Yair P.",
      "Cyntya F.",
      "Veronica G.",
      "Rafael M.",
      "Dulce S.",
      "Manuel M.",
      "Beatriz Z.",
      "Alejandra L.",
      "Roberto N.",
      "Estela Z.",
      "Maria P.",
      "Julio L.",
      "Veronica R.",
      "Jesus M.",
      "Stephani S.",
      "Ofelia V.",
      "Ulises G.",
      "Sergio C.",
      "Erika E.",
      "Ricardo H.",
      "Samantha O.",
      "Ariel R.",
      "Victor M.",
      "Leslie T.",
      "Jessica C.",
      "Nancy V.",
      "Cinthya C.",
      "Filiberto H.",
      "Vianey G.",
      "Oscar G.",
      "Alejandro U.",
      "Luis C.",
      "Karla C.",
      "Sara S.",
      "Juan A.",
      "Martha C.",
      "Ana C.",
      "Lucia G.",
      "Alexis C.",
      "Norma F.",
      "Jose S.",
      "Danira G.",
      "Juan U.",
      "Maria L.",
      "Ana R.",
      "Lady B.",
      "Erick H.",
      "Maria A.",
      "Mauricio R.",
      "Irma A.",
      "Claudia P.",
      "Francisca F.",
      "Paula S.",
      "Elizabeth C.",
      "Martha R.",
      "Sofia S.",
      "Nallely H.",
      "Julio T.",
      "Maria C.",
      "Luis A.",
      "Andrea C.",
      "Ruth M.",
      "Vera B.",
      "Chrysthian R.",
      "Claudia G.",
      "Maria B.",
      "Andros L.",
      "Brenda D.",
      "Jaime V.",
      "Elizabeth J.",
      "Luis O.",
      "Maleny D.",
      "Erika V.",
      "Juan C.",
      "Maria P.",
      "Carolina G.",
      "Federico A.",
      "Maria M.",
      "Miriam C.",
      "Jorge M.",
      "Diego G.",
      "Rebeca P.",
      "Ruben M.",
      "Carlos T.",
      "Jorge H.",
      "Rodrigo M.",
      "Nancy P.",
      "Barbara C.",
      "Dayana C.",
      "Patricia S.",
      "Miguel B.",
      "Pascual P.",
      "Demetrio C.",
      "Diana E.",
      "Martha P.",
      "Albert M.",
      "Gabriel A.",
      "Brenda C.",
      "Ismael M.",
      "Jesus C.",
      "Anabel F.",
      "Juan C.",
      "Gustavo G.",
      "Jessica G.",
      "Lilia P.",
      "Elias G.",
      "Maria H.",
      "Luis C.",
      "Veronica G.",
      "Nayeli P.",
      "Ismael A.",
      "Alicia D.",
      "Dulce A.",
      "Maria G."
    ]
  },
  {
    week: 'Semana 7',
    dateInit: new Date(2024, 8, 27, 0, 0, 0, 0),
    dateEnd: new Date(2024, 9, 4, 23, 59, 59, 999),
    winner: '',
    dailyWinner: []
  },
  {
    week: 'Semana 8',
    dateInit: new Date(2024, 9, 5, 0, 0, 0, 0),
    dateEnd: new Date(2024, 9, 10, 23, 59, 59, 999),
    winner: '',
    dailyWinner: []
  }
]
